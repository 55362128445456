<template>
  <div class="container">
    <HeaderFooter />
    <main class="Main">
      <!-- 主体 -->
      <div class="pageContainer">
        <el-carousel
          indicator-position="inside"
          :height="carouselHeight"
          arrow="always"
        >
          <el-carousel-item>
            <img
              src="@/assets/images/picOne.png"
              width="100%"
              class="item_style"
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="@/assets/images/picTwo.png"
              width="100%"
              class="item_style"
            />
          </el-carousel-item>
          <el-carousel-item>
            <img
              src="@/assets/images/picThree.png"
              width="100%"
              class="item_style"
            />
          </el-carousel-item>
           </el-carousel
        >
      </div>
      <div class="content">
        <div class="contentStyle">
          <span class="spanOne">共享计算</span>
          <span class="spanLine"></span>
          <span class="spanTwo">创新融合、开放共享，汇聚海量碎片化资源。 </span>
        </div>
        <div class="contentStyle">
          <span class="spanOne">节省宽带</span>
          <span class="spanLine"></span>
          <span class="spanTwo">就近获取热点内容，节省用户宽带。</span>
        </div>
        <div class="contentStyle">
          <span class="spanOne">流畅高效</span>
          <span class="spanLine"></span>
          <span class="spanTwo">自动寻找最近的数据源，快速获取播放数据。</span>
        </div>
        <div class="contentStyle" style="border-right: none">
          <span class="spanOne">边缘计算</span>
          <span class="spanLine"></span>
          <span class="spanTwo">软硬件一站式边缘计算服务和解决方案。</span>
        </div>
      </div>
      <div class="titleContent">
        <span>朝天云 共享闲置带宽</span><span>坐收“余”利</span>
      </div>
      <div class="titleContent2">
        利用路由长期浪费的空闲带宽和空闲存储空间实现带宽资源共享的经济模式，从而实现变现，它对用户上网方式无任何限制，对上网用户基本无“影响”。
      </div>
      <div class="imageStyle">
        <img src="@/assets/images/youyu_icon.png" />
      </div>
    </main>
    <!-- 官网底部 -->
    <FooterComponent />
  </div>
</template>

<script>
import { Http, getUrl } from "@/service/index.js";
import HeaderFooter from "@/components/HeaderFooter.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: { HeaderFooter, FooterComponent },
  data() {
    return {
      Http,
      Url: "",
      carouselHeight: "",
    };
  },
  created() {
    this.carouselHeight = (document.body.clientWidth * 570) / 1920 + "px";
  },
  mounted() {
    //监听屏幕宽度变化来修改滚动图的高度
    window.onresize = () => {
      return (() => {
        this.carouselHeight = (document.body.clientWidth * 570) / 1920 + "px";
      })();
    };
  },
  methods: {
    OncilckUpload() {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = Http + this.Url;
      link.setAttribute("download", name);
      document.body.appendChild(link);
      link.click();
    },
    async getUrl() {
      const res = await getUrl();
      const Url = res.data.url;
      this.Url = Url;
    },
  },
};
</script>

<style lang="less" scoped>
.Main {
  width: 100%;
}
.item_style {
  max-width: 100%;
  max-width: 100%;
  object-fit: contain;
}
.content {
  width: 100%;
  height: 97px;
  background: #f0f4f7;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentStyle {
  margin: 12px 0;
  margin-left: 20px;
  width: 305px;
  box-sizing: border-box;
  border-right: 2px solid #d3d8db;
  display: flex;
  flex-direction: column;
  .spanOne {
    font-size: 22px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
  }
  .spanTwo {
    font-size: 14px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #333333;
  }
  .spanLine {
    display: inline-block;
    width: 30px;
    height: 3px;
    background: #140052;
    margin: 8px 0;
  }
}
.titleContent {
  margin: 71px 0;
  text-align: center;
  width: 100%;
  font-size: 65px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  display: flex;
  flex-direction: column;
}
.titleContent2 {
  width: 100%;
  padding: 0 20%;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  line-height: 45px;
  color: #333333;
}
.imageStyle {
  width: 100%;
  display: flex;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}
</style>
