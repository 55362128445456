<!--
 * @Author: zhangweinan
 * @Date: 2023-07-11 21:35:23
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-23 23:09:46
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\views\Services\index.vue
-->
<template>
  <div id="use">
    <HeaderFooter />
    <main class="Main">
      <div class="banner">
        <div class="bg">
          <div class="bg-img">
            <img src="../../assets/images/use-bg.png" width="100%" />
          </div>
          <div class="bg-word bg-big">
            开创共享计算模式，重新定义云计算未来。
          </div>
          <div class="bg-word bg-small">
            共享家庭闲置流量，关注朝天云小程序，实时查看收益。
          </div>
        </div>
      </div>
      <div class="imgDiv">
        <div class="agreement">
          <img src="../../assets/images/group_1.png" />
          <img src="../../assets/images/group_2.png" />
          <img src="../../assets/images/group_3.png" />
        </div>
      </div>
      <div class="stepContent">
        <span class="stepTitle">最多三步，实现稳定收益</span>
        <div class="all_step">
          <span class="column_style">
            <img src="../../assets/images/step_1.png" alt="" />
            <span class="content_stype">
              <span>第一步 加入计划</span>
              <span>灵活多样的合作模式</span>
            </span>
          </span>
          <span class="column_style">
            <img src="../../assets/images/step_2.png" alt="" />
            <span class="content_stype">
              <span>第二步 绑定设备</span>
              <span>提供多种绑定方式，支持各类设备接入</span>
            </span>
          </span>
          <span class="column_style">
            <img src="../../assets/images/step_3.png" alt="" />
            <span class="content_stype">
              <span>第三步 查看收益</span>
              <span>设备实时管理，收益实时查看</span>
            </span>
          </span>
        </div>
      </div>
    </main>

    <!-- 官网底部 -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderFooter from "@/components/HeaderFooter.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    HeaderFooter,
    FooterComponent,
  },
};
</script>

<style scoped lang="less">
.Main {
  width: 100%;
}
.banner {
  width: 100%;
}
.bg {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-img {
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.bg-word {
  color: #fff;
  font-family: Source Han Sans SC;
  text-align: center;
}
.bg-big {
  position: absolute;
  top: 11%;
  font-size: 65px;

  font-weight: bold;
}
.bg-small {
  position: absolute;
  top: 16%;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}
.imgDiv {
  width: 100%;
  height: 500px;
  position: relative;
}
.agreement {
  position: absolute;
  top: -45%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    margin-left: 20px;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}
.stepContent {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  .stepTitle {
    font-size: 65px;
    font-family: Source Han Sans SC;
    font-weight: bold;
    color: #333333;
  }
  .all_step {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .column_style {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 25%;
      img {
        width: 266px;
        height: 326px;
      }
    }
  }
}
.content_stype {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  span {
    font-family: Source Han Sans SC;
    color: #333333;
    &:nth-child(1) {
      font-size: 44px;
      font-weight: bold;
    }
    &:nth-child(2) {
      margin-top: 20px;
      font-size: 24px;
      font-weight: 500;
    }
  }
}
</style>
