<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style>
/* 版心 */
#app {
  width: 100%;
  position: absolute;
  overflow: hidden;
  /* margin: 0 auto; */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
/* ::-webkit-scrollbar {
  background-color: lightgray;
  width: 10px;
  height: 10px;
  background-clip: padding-box;
} */
</style>
