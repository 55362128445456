/*
 * @Author: zhangweinan
 * @Date: 2023-07-10 20:03:34
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-11 22:19:34
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\service\index.js
 */
import axios from 'axios'

axios.defaults.baseURL = `/ucenter`

// 添加请求拦截器
axios.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // 携带token令牌
    let resData = JSON.parse(localStorage.getItem('mytoken')) || []
    config.headers['Authorization'] = resData.token
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    return response.data

  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
)

export const fetch = axios

//下载包基准路径
export const Http = 'https://api.kxsecu.com/client'

/**
 * @description:获取下载包后缀路径
 * @param {*} params:{type:String}
 * @return {*Promise}
 */
export const getUrl = () => axios.post(`/api/version/get`, { type: 'pc' })
