<!--
 * @Author: zhangweinan
 * @Date: 2023-07-11 21:35:23
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-23 23:06:21
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\views\Cooperation\index.vue
-->
<template>
  <div id="use">
    <HeaderFooter />
    <main class="Main">
      <div class="banner">
        <div class="bg">
          <div class="bg-img">
            <img src="../../assets/images/cooperation.png" width="100%" />
          </div>
          <div class="bg-word bg-big">开放兼容的共享产品</div>
          <div class="bg-word bg-small">
            拥抱客户 追求卓越 创造价值 期待与您相约
          </div>
        </div>
        <div class="imgDiv">
          <img src="@/assets/images/org_icon.png" width="100%" />
        </div>
        <div class="agreement">
          <div class="agreement_detail">
            <div>7*12小时支持服务 团队培训 专属客服 远程指导 及时响应</div>
            <div>期待与您精诚合作，携手共赢，共创行业领先。</div>
          </div>
        </div>
      </div>
    </main>

    <!-- 官网底部 -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderFooter from "@/components/HeaderFooter.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    HeaderFooter,
    FooterComponent,
  },
};
</script>

<style scoped lang="less">
.Main {
  width: 100%;
}
.banner {
  width: 100%;
}
.bg {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-img {
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.bg-word {
  width: 65%;
  color: #fff;
  font-family: Source Han Sans SC;
  text-align: center;
}
.bg-big {
  position: absolute;
  top: 15%;
  font-size: 65px;
  font-weight: bold;
}
.bg-small {
  position: absolute;
  top: 20%;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.imgDiv {
  margin-top: 110px;
  margin-bottom: 220px;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.agreement {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 95px;
}
.agreement_detail {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 41px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;

  div:nth-child(2) {
    margin-top: 35px;
    font-size: 30px;
    font-weight: 500;
    line-height: 47px;
    text-align: center;
  }
}
</style>
