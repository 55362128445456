<!--
 * @Author: zhangweinan
 * @Date: 2023-07-11 21:35:23
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-13 01:05:50
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\views\News\index.vue
-->
<template>
  <div id="use">
    <HeaderFooter />
    <main class="Main">
      <div class="banner">
        <div class="itemDiv">
          <div class="item_one">
            <div class="title">最新动态1</div>
            <div class="time">2023-07-06</div>
            <div class="item_content">
              本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。
            </div>
            <div class="btn">查看详情</div>
          </div>
          <div class="item_two"></div>
        </div>
        <div class="itemDiv">
          <div class="item_one">
            <div class="title">最新动态1</div>
            <div class="time">2023-07-06</div>
            <div class="item_content">
              本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。
            </div>
            <div class="btn">查看详情</div>
          </div>
          <div class="item_two"></div>
        </div>
        <div class="itemDiv">
          <div class="item_one">
            <div class="title">最新动态1</div>
            <div class="time">2023-07-06</div>
            <div class="item_content">
              本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。本次项目中，中立的云计算服务商UCloud优刻得作为技术支持方，再度与信通院携手，配合进行评测准备工作，全程负责线上评测环境的部署与搭建，此外还提供私有云、安全屋沙箱等云平台产品及技术服务支持，确保一切评测工作在环境公平、系统稳定、数据安全的基础上顺利开展。
            </div>
            <div class="btn">查看详情</div>
          </div>
          <div class="item_two"></div>
        </div>
      </div>
    </main>

    <!-- 官网底部 -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderFooter from "@/components/HeaderFooter.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    HeaderFooter,
    FooterComponent,
  },
};
</script>

<style scoped lang="less">
.Main {
  width: 100%;
  margin-bottom: 50px;
}
.banner {
  padding-top: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  .itemDiv {
    width: 70%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 50px 0;
    height: 380px;
    border-bottom: 1px solid #6666665c;
    .item_one {
      flex: 1;
      height: 280px;
      margin-right: 56px;
      font-size: 12px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #666666;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
      }
      .time {
        color: #333333;
        margin: 0.2% 0;
      }
      .item_content {
        line-height: 30px;
      }
      .btn {
        margin-top: 0.5%;
        cursor: pointer;
        color: #3860f4;
      }
    }
    .item_two {
      width: 339px;
      height: 280px;
      border: 2px solid yellow;
    }
  }
  .itemDiv:last-child {
    border-bottom: none;
  }
}
</style>
