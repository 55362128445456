<!--
 * @Author: zhangweinan
 * @Date: 2023-07-10 20:03:34
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-13 15:15:11
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\components\HeaderFooter.vue
-->
<template>
  <div class="headerFooter">
    <div class="Header">
      <div class="left">
        <img class="product-logo" src="@/assets/images/logo.png" />
      </div>
      <div class="right">
        <div class="titleName">
          <router-link to="/Main">首页</router-link>
        </div>
        <div class="titleName">
          <router-link to="/Services">产品服务</router-link>
        </div>
        <div class="titleName">
          <router-link to="/Cooperation">合作模式</router-link>
        </div>
        <!-- <div class="titleName">
          <router-link to="/News">最新动态</router-link>
        </div> -->
        <div class="titleName">
          <router-link to="/Use">联系我们</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
a.router-link-active {
  color: #0ae6fa;
}
.Header {
  width: 100%;
  height: 84px;
  background: #0a0125;
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
}
.left {
  width: 40%;
  display: flex;
  justify-content: center;
}
.right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  height: 84px;
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: bold;
}
.product-logo {
  width: 203px;
  height: 84px;
}
.titleName {
  margin-right: 60px;
}

.header-nav {
  width: 50%;
  height: 32px;
  list-style: none;
  display: flex;
}

.index-one {
  font-weight: normal;
}
.index-two {
  font-weight: normal;
}

button {
  border-radius: 5px;
  width: 88px;
  height: 38px;
  background: #5cae00;
  color: rgb(255, 255, 255);
  font-size: 16px;
}

a {
  font-size: 22px !important;
  color: #fff;
}
</style>
