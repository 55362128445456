<!--
 * @Author: zhangweinan
 * @Date: 2023-07-10 20:03:34
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-12 19:40:38
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\components\FooterComponent.vue
-->
<template>
  <div class="footer">
    <div class="footer_font">
      <p class="footer_link">重庆朝天云信息科技有限公司 所有权</p>
      <p>copyright@渝ICP备2023005431号-1</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* 底部 */
.footer {
  width: 100%;
  height: 135px;
  background: #d2d8de;
  color: rgb(255, 255, 255);
  z-index: 1;
}

.footer_font {
  width: 100%;
  height: 100%;
  font-style: normal;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.8% 0;
}
.footer_link {
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
