<!--
 * @Author: zhangweinan
 * @Date: 2023-07-11 21:35:23
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-14 10:03:06
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\views\Use\Use.vue
-->
<template>
  <div id="use">
    <HeaderFooter />
    <main class="Main">
      <div class="bg">
        <div class="bg-img">
          <img src="../../assets/images/lianxi.png" width="100%" />
        </div>
      </div>
      <div style="padding: 0 10%">
        <div class="address">公司地址</div>
        <!--使用百度地图-->
        <div id="mapContainer" class="mapContainer"></div>
        <div class="foot_div">
          <!--地址邮箱信息-->
          <div class="detailContent">
            <span class="spanStyle"
              ><span class="itemSpan">地址</span
              ><span>重庆市江北区石马河街道福康路27号13-6</span></span
            >
            <span class="spanStyle"
              ><span class="itemSpan">邮箱</span
              ><span>chaotianyun@aliyun.com</span></span
            >
            <span class="spanStyle"
              ><span class="itemSpan">电话</span><span>18881708588</span></span
            >
            <!-- <span class="spanStyle"
            ><span class="itemSpan"></span><span>028-123456</span></span
          > -->
          </div>
          <div class="imgDiv">
            <!-- <div class="item_div">
            <img src="@/assets/images/code.png" />
            <span> 企业微信 </span>
          </div> -->
            <div class="item_div">
              <img src="@/assets/images/code.png" />
              <span> 小程序 </span>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- 官网底部 -->
    <FooterComponent />
  </div>
</template>

<script>
import HeaderFooter from "@/components/HeaderFooter.vue";
import FooterComponent from "@/components/FooterComponent.vue";
export default {
  components: {
    HeaderFooter,
    FooterComponent,
  },
  data() {
    return {
      map: "",
    };
  },
  mounted() {
    this.createMap();
  },
  methods: {
    //创建地图
    createMap() {
      var map = new BMapGL.Map("mapContainer");
      this.map = map;
      //设置中心点坐标和地图级别
      map.centerAndZoom(new BMapGL.Point(106.496337, 29.605127), 13);
      //开启滚轮缩放
      map.enableScrollWheelZoom(true);
      //监听鼠标点击

      let latlng = {
        lat: 29.605127,
        lng: 106.496337,
      };
      this.setPoint(latlng);
    },
    //设置坐标
    setPoint(point) {
      //1.清理所有覆盖物
      this.map.clearOverlays();
      //2.创建点坐标
      var marker = new BMapGL.Marker(point);
      this.map.addOverlay(marker);
      //3.显示当前坐标
      const { lng, lat } = point;
      //4.将地图中心点移动到该坐标点位
      this.map.panTo(point);
    },
  },
};
</script>

<style scoped lang="less">
.Main {
  width: 100%;
}
.banner {
  width: 100%;
}
.bg {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-img {
  width: 100%;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
.address {
  margin: 3% 0;
  width: 70%;
  padding-bottom: 1%;
  font-size: 41px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 1px dashed #666666;
}
.mapContainer {
  min-height: 310px;
}
.foot_div {
  display: flex;
  justify-content: space-around;
  margin-top: 1%;
}
.detailContent {
  display: flex;
  flex-direction: column;
  height: 210px;
  box-sizing: border-box;
  justify-content: center;
  .spanStyle {
    height: 47px;
    line-height: 47px;
    font-size: 30px;
    font-family: Source Han Sans SC;
    font-weight: 500;
    color: #666666;

    .itemSpan {
      display: inline-block;
      width: 80px;
      font-weight: bold;
      color: #333333;
    }
  }
}

.imgDiv {
  margin-bottom: 220px;
  display: flex;
  justify-content: flex-end;
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  .item_div {
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    text-align: center;
    span {
      font-size: 30px;
      font-family: Source Han Sans SC;
      font-weight: bold;
      color: #333333;
    }
  }
}
</style>
