/*
 * @Author: zhangweinan
 * @Date: 2023-07-10 20:03:34
 * @LastEditors: zhangweinan
 * @LastEditTime: 2023-07-13 15:12:04
 * @Description: file content
 * @FilePath: \jingwangbeibei-product-guanw-master\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

import Main from '@/views/Main/Main'
import Use from '@/views/Use/Use'
import Services from '@/views/Services/index'
import Cooperation from '@/views/Cooperation/index'
import News from '@/views/News/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    redirect: '/Main'
  },
  {
    path: '/Main',
    name: 'Main',
    component: Main
  },
  {
    path: '/Use',
    name: 'Use',
    component: Use
  },
  {
    path: '/Services',
    name: 'Services',
    component: Services
  }, {
    path: '/Cooperation',
    name: 'Cooperation',
    component: Cooperation
  }, {
    path: '/News',
    name: 'News',
    component: News
  }


]

const router = new VueRouter({
  routes
})

export default router
